import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Image from 'gatsby-image';
import { mixin } from '@src/settings';
import { createBreakpoint } from 'react-use';


import Hero from '@components/common/hero';
import PageIntro from '@components/common/page-intro';
import ContentsIntro from '@components/common/contents-intro';
import ContactIntro from '@components/common/contact-intro';

import Service_hero__mds from '@img/svg/service/hero__mds.svg';
import Service_guide__mds__strength from '@img/svg/common/service-guide__mds--strength.svg';
import Service_guide__mds__facility from '@img/svg/common/service-guide__mds--facility.svg';

import Layout from '@components/common/layout';
import Column from '@components/services/column';
import { Container } from '@components/services/styles';
import { color, breakpoint } from '@src/settings';

const useBreakpoint = createBreakpoint(breakpoint);

const Services_page = ({ data }) => {

	const bp = useBreakpoint();	

	return (
		<Layout baseBgColor={ color.paleGray }>

			<Hero
				img={ data["heroImg"]}
				title={<Service_hero__mds />}
				subTitle="事業紹介"
			/>
			
			<PageIntro
				title={<>徹底された一貫生産体制</>}
				subtitle={<>大手メーカーの一次サプライヤーとして技術革新、今後も車体生産業の先頭を走ってまいります。</>}
			 />

			<Container >

				<Column
					className="column"
					img={ bp == "pc" ?  data["Service_1"] : data["Service_1_sp"] }
					title="生産フロー"
					en={<>PRODUCTION FLOW</>}
					cap="プロトタイプの作成・改良を含む、車体の金型の設計・製造といった金型事業。それらのプレス・溶接・組み立てを経たボディーパーツの組立事業まで、すべて同じ工場内で行っております。当社は、課題改善、品質向上がスピーティに行える、一貫生産体制を整えているマレーシア唯一の企業です。"
					to="/flow"
				/>

				<Column
					className="column"
					img={ data["Service_2"] }
					img={ bp == "pc" ?  data["Service_2"] : data["Service_2_sp"] }
					title="製品紹介"
					en={<>OUR PRODUCTS</>}
					cap="外板部品、シェルボディ部品、アンダー部品、アッセンブリ部品、精密機械加工部品まで、サイズも形状も様々な自動車の車体製造を行っています。ご要望に合わせた、オーダメイドの設計・製造も承ります。"
					to="/products"
				/>

				<Column
					className="column"
					img={ bp == "pc" ?  data["Service_3"] : data["Service_3_sp"] }
					title="品質・環境への取り組み"
					en={<>QUALITY</>}
					cap="当社が着手する事業の指針となっているのは、グローバルな視点で知られるトヨタ生産方式（TPS）です。お客様のニーズをしっかり汲み取り、必要なものだけ無駄なく生産する。それが製品の品質向上だけでなく、働きやすい環境づくり、さらに地球環境との共生に向けた取り組みにつながっていくと考えています。"
					to="/quality"
				/>
			</Container>

			<ContentsIntro
				firstItem="strength"
				secondItem="facility"
			/>

		</Layout> 
	)
}

export default Services_page


export const query = graphql`
	query serviceImgs{

		heroImg: file( relativePath: { eq: "services/hero-img.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Service_1: file( relativePath: { eq: "services/service_1.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Service_2: file( relativePath: { eq: "services/service_2.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Service_3: file( relativePath: { eq: "services/service_3.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Service_1_sp: file( relativePath: { eq: "services/service-flow--sp.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Service_2_sp: file( relativePath: { eq: "services/service-products--sp.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},

		Service_3_sp: file( relativePath: { eq: "services/service-quality--sp.jpg" }){
			childImageSharp {
				fluid(quality: 90, maxWidth: 950){
					...GatsbyImageSharpFluid_withWebp 
				}
			}
		},


	},
`;
